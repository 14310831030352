import React, { useEffect,useState } from 'react'

import { Button } from '../components/button'
import { Container } from '../components/container'

// import { Link } from 'gatsby';
const ThankYouDT = () => {
  const getFormName = localStorage.getItem('FormName')
  function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  
  // AB-test to display blueghost-version or current-version components
  const [websiteVersion, setWebsiteVersion] = useState("")
  const webVersions = ['new-calc-A', 'new-calc-B']
  
  useEffect(() => {
    const curVersion = localStorage.getItem("website-version")
    const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
    const randomVersion = webVersions[randomIndex]
    if (!curVersion || curVersion === undefined || !webVersions.includes(curVersion)) {
      localStorage.setItem("website-version", randomVersion)
      setWebsiteVersion(randomVersion)
    } else {
      setWebsiteVersion(curVersion)
    }
  }, [])
  
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
    window.dataLayer.push({
      pageURL: "/dekovaca-testdrive",
      testovani: websiteVersion,
      event: "ga.pageview"

    })
  }, [])

  useEffect(() => {
    const isEn = document.location.pathname.includes('/en')

    const userData = localStorage.getItem('order-food-contact-information')
    const data = JSON.parse(userData)

    const name = data?.name
    const phone = data?.phone
    const email = data?.email
    const zip = data?.deliveryAddress?.components?.postcode
      ? data?.deliveryAddress?.components?.postcode
      : ''
    const city = data?.city?.label ? data?.city?.label : ''
    const state = 'CZ'

    let firstName = ''
    let lastName = ''

    if (name) {
      const nameParts = name.split(' ')
      firstName = nameParts[0]
      lastName = nameParts.slice(1).join(' ')
    }

    const user_data = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state,
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'purchase',
        pageURL: window.location.pathname,
        user_data,

      })
  }, [])

  const parsedData = JSON.parse(localStorage.getItem('Calculation-data'))
  const getConversationData = JSON.parse(localStorage.getItem('conversation'))
  const parsedOnlineData = JSON.parse(localStorage.getItem('order-info'))
  const isWeekLength6 =
    parsedOnlineData && parsedOnlineData.isOrderFoodWeekMeal5DaysLength
      ? '5'
      : '6'
  const isPaymentOnline =
    parsedOnlineData && parsedOnlineData.onlinePayment
      ? 'online-card'
      : 'faktura'
  const deliveryTimeRange =
    parsedOnlineData && parsedOnlineData.deliveryTime &&
    `${parsedOnlineData.deliveryTime[0]}-${parsedOnlineData.deliveryTime[1]}`


  const pushDataLayerEvent = formName => {
    let event
    let pageURL
    let variables = {}

    switch (formName) {
      case 'vege-objednavka':
        window.dataLayer = window.dataLayer || []
        window.dataLayer &&
          window.dataLayer.push({
            event: 'conversion_vegetarian_menu_order',
            pageURL: '/dekovacka-testdrive',
          })
        break
      case 'konzultace-objednavka':
        event = 'conversion_nutritionist_consultation'
        pageURL = '/dekovacka-testdrive'
        break
      case 'hromadna-objednavka':
        event = 'conversion_multi_order'
        pageURL = '/dekovacka-testdrive'
        break
      case 'hromadna-objednavka_en':
        event = 'conversion_multi_order'
        pageURL = '/dekovacka-testdrive'
        break
      case 'darkovy-certifikat':
        event = 'conversion_gift_voucher'
        pageURL = '/dekovacka-testdrive'

        break
      case 'conversion-callback':
        event = 'conversion_callback'
        pageURL = '/dekovacka-testdrive'
        break
      case 'demo-objednavka':
        event = 'conversion_demo_order'
        pageURL = '/dekovacka-testdrive'
        break
      case 'demo-objednavka_en':
        event = 'conversion_demo_order'
        pageURL = '/dekovacka-testdrive'
        break
      case 'order-call':
        event = 'conversion_callback'
        pageURL = `/dekovacka-testdrive/price_daily=${getConversationData.price}&&program=${getConversationData.program}&&program_length=${getConversationData.lenght}&&meal_count
    =${getConversationData.numberOfMeals}&&person_count=${getConversationData.person}&&testovani=${getConversationData.testovani}&&coupon=${getConversationData.promo}&&kcal_count=${getConversationData.kalorie}&&menu_choice=${getConversationData.menu}&&sex_choice=${getConversationData.sex}&&week_length=${getConversationData.weekLenght}`
    variables = {
          price_daily: getConversationData.price,
          program: getConversationData.program,
          program_length: getConversationData.lenght,
          meal_count: getConversationData.numberOfMeals,
          person_count: getConversationData.person,
          testovani: getConversationData.testovani,
          coupon: getConversationData.promo,
          kcal_count: getConversationData.kalorie,
          menu_choice: getConversationData.menu,
          sex_choice: getConversationData.sex,
          week_length: getConversationData.weekLenght,
        }
        break
      case 'kalkulacka':
        event = 'conversion_calculator'
        pageURL = `/dekovacka-testdrive/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
          =${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`
          variables = {
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        }
        break
      case 'kalkulacka_en':
        event = 'conversion_calculator'
        pageURL = `/dekovacka-testdrive/price_daily=${parsedData.price}&&program=${parsedData.plan}&&program_length=${parsedData.program}&&meal_count
  =${parsedData.menu}&&person_count=${parsedData.osob}&&testovani=${parsedData.testovani}&&coupon=${parsedData.promo}`
  variables = {
          price_daily: parsedData.price,
          program: parsedData.plan,
          program_length: parsedData.program,
          meal_count: parsedData.menu,
          person_count: parsedData.osob,
          testovani: parsedData.testovani,
          coupon: parsedData.promo,
        }
        break
      case 'online-order':
        event = 'conversion_online_order'
        pageURL = `/dekovacka-testdrive/price_daily=${parsedOnlineData.price}&&program=${parsedOnlineData.Program}&&program_length=${parsedOnlineData.length}&&meal_count=${parsedOnlineData.numberOfMeals}&&person_count=${parsedOnlineData.NumberOfPeople}&&testovani
        =${parsedOnlineData.testovani}&&coupon=${parsedOnlineData.promo}&&kcal_count=${parsedOnlineData.kcal}&&menu_choice=${parsedOnlineData.CustomerMenu}&&sex_choice=${parsedOnlineData.CustomerGender}&&week_length=${isWeekLength6}&&company_order=${parsedOnlineData.isCompanyOrder}&&city=${parsedOnlineData.city}&&payment_method=${isPaymentOnline}&&delivery_time_range=${deliveryTimeRange}&&price=${parsedOnlineData.price}&&discount=${parsedOnlineData.PriceDiscount}`
        variables = {
          price_daily: parsedOnlineData.price_daily,
          program: parsedOnlineData.Program,
          program_length: parsedOnlineData.length,
          meal_count: parsedOnlineData.numberOfMeals,
          person_count: parsedOnlineData.NumberOfPeople,
          testovani: parsedOnlineData.testovani,
          coupon: parsedOnlineData.promo,
          kcal_count: parsedOnlineData.kcal,
          menu_choice: parsedOnlineData.CustomerMenu,
          sex_choice: parsedOnlineData.CustomerGender,
          week_length: isWeekLength6,
          company_order: parsedOnlineData.isCompanyOrder,
          city: parsedOnlineData.city,
          payment_method: isPaymentOnline,
          delivery_time_range: deliveryTimeRange,
          price: parsedOnlineData.price,
          discount: parsedOnlineData.PriceDiscount,
        }
        break
      default:
        break
    }

    if (event) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer &&
        window.dataLayer.push({
          event: event,
          pageURL: pageURL,
          ...variables
        })
    }
  }

  useEffect(() => {
    pushDataLayerEvent(getFormName)
  }, [getFormName])

  return (
    <>
      <Container className="text-center">
        <h1 className="text-center" style={{ marginTop: '100px' }}>
          Vaše žádost byla
          <br />
          <span style={{ color: 'var(--color-green)' }}>úspěšně přijata</span>
        </h1>
        <Button type="primary">
          <a href="/">Zpět na hlavní stránku</a>
        </Button>
      </Container>
    </>
  )
}

export default ThankYouDT
